import React, { Component, PropTypes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Select extends Component {
    state = {
        open: false,
        selectedIndex: -1,
        value: ''
    };

    handleChange = (e) => {
        e.preventDefault();

        this.setState({
            open: true,
            selectedIndex: -1,
            value: e.target.value
        });

        this.props.onChange(e.target.value);
    };

    handleKeyUp = ({key}) => {
        const { selectedIndex } = this.state;

        if(key === "ArrowUp"){
            this.select(selectedIndex - 1);
        }

        if(key === "ArrowDown"){
            this.select(selectedIndex + 1);
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.handleSelect(this.state.value);
    };

    handleSelect = (selected) => {
        this.setState({
            value: selected,
            open: false
        });

        this.props.onSelect(selected);
    };

    // ---

    select = (index) => {
        const { list } = this.props;

        if(index < -1) index = -1;
        if(index >= list.length) index = list.length - 1;

        this.setState({
            selectedIndex: index,
            value: list[index] ||  ''
        });
    };

    // ---

    render() {
        const { list, placeholder } = this.props;
        const { open, selectedIndex, value } = this.state;

        return (
            <form
                className="Select"
                onKeyUp={this.handleKeyUp}
                onSubmit={this.handleSubmit}
            >
                <div className="input" style={{display:"flex", alignItems: "center"}}>
                    <FontAwesomeIcon icon="search" style={{marginLeft: 10, marginRight: 6}}/>
                    <input type="text"
                           value={value}
                           placeholder={placeholder}
                           onChange={this.handleChange}
                    />
                </div>

                {open &&
                <div className="dropdown" style={{marginLeft: 32, width: "70vw"}}>
                    {list.map((el, i) =>
                        <div
                            key={i}
                            className={"item " + (i == selectedIndex ? "selected": "")}
                            onClick={() => this.handleSelect(el)}
                        >
                            <span>{el}</span>
                            <span className="tip">master</span>
                        </div>
                    )}
                </div>}
            </form>
        )
    }
}

export default Select;