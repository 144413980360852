import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileIcon = ({application = ""}) => {

    if (application === "MD") return <FontAwesomeIcon icon={["fab", "markdown"]} style={{ marginRight: 8 }} />
    else if (application === "MDX") return <FontAwesomeIcon icon={["fas", "underline"]} style={{ marginRight: 8 }} />
    else if (application === "SWAGGER")
        return <FontAwesomeIcon icon={["fas", "code"]} style={{ marginRight: 8 }} />
    return <FontAwesomeIcon icon="book" style={{ marginRight: 8 }} />;
}

export default FileIcon