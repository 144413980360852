import React from "react";
import MarkdownViewer from "./MarkdownViewer";
import SwaggerUI from "swagger-ui-react";
import MarkdownXViewer from "./MarkdownXViewer";

import "swagger-ui-react/swagger-ui.css";
import { Typography } from "@material-ui/core";

const Viewer = ({ fileName, branch, basename, content, application }) => {
    if (application === "MD") return <MarkdownViewer branch={branch} basename={basename} content={content} />;
    else if (application === "SWAGGER")
        return (
            <SwaggerUI
                url={`/api/file-content/${basename}/${fileName}?requestOriginal=true&application=${application}&branch=${branch}`}
            />
        );
    else if (application === "MDX") return <MarkdownXViewer filename={fileName} content={content} />;

    return (
        <Grid contaienr>
            <Grid item xs={12}>
                <Typography variant="h5">
                    {"Esse tipo de arquivo não tem nenhuma configuração para ser renderizado. Segue o conteúdo dele"}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {content}
            </Grid>
        </Grid>
    );
};

export default Viewer;
