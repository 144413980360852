import React, { Component } from 'react';
import bindComponentToApi from './bindComponentToApi.js';
import Api from './Api';
import BranchSelector from './BranchSelector';
import MarkdownViewer from './MarkdownViewer';
import NavMenu from './NavMenu';
import Select from './Select';
import menuState from "./MenuState";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileIcon from './FileIcon.js';
import Viewer from './Viewer.js';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menu: props.menu,
            project: props.project,
            branch: props.branch,
            branches: props.branches,
            projects: [],
            basedir: props.basedir,
            fileName: props.fileName,
            fileContent: props.fileContent || "",
            compatibleApplication: props.compatibleApplication,
        };
    }

    componentDidMount() {
        const {fileName, project} = this.props;

        if (!fileName) {
            this.api.getFile(project);
        }
    }

    // ---

    handleSelectProject = (project) => {
        menuState.clear();
        window.location="/home/" + project;
    };

    render() {
        const {
            menu,
            project,
            branch,
            branches,
            projects,
            basedir,
            fileName,
            fileContent,
            compatibleApplication,
        } = this.state;

        return (
            <div className="App">
                <Header
                    projects={projects}
                    onChange={(query) => this.api.getProjects(query)}
                    onSelect={(selected) => this.handleSelectProject(selected)}
                />

                <FileNavigation branch={branch} branches={branches} project={project} />

                <div className="FileContent">
                    <NavMenu branch={branch} branches={branches} basename={"/home/" + project} menu={menu} />

                    <FilePanel title={fileName} application={compatibleApplication.$name}>
                        <Viewer
                            fileName={fileName}
                            branch={branch}
                            basename={project + "/" + basedir}
                            content={fileContent}
                            application={compatibleApplication.$name}
                        />
                    </FilePanel>
                </div>
            </div>
        );
    }
}

const Header = ({ projects, onChange, onSelect }) => {
    return (
        <div style={{ display: "flex" }}>
            <a className="Logo" href="/" title="Documentar demais é tão ruim quanto não documentar. Seja objetivo." />
            <Search projects={projects} onChange={onChange} onSelect={onSelect} />
        </div>
    );
};

const Search = ({ projects, onChange, onSelect}) => {
    return (
        <div className="Search">
            <div style={{marginLeft:"20%"}}>
                <Select
                    list={projects}
                    placeholder="ex: velab, touch-commons, genesis"
                    onChange={onChange}
                    onSelect={onSelect}
                />
            </div>
        </div>
    );
};

const FileNavigation = ({ branch, branches, project }) => {
    return (
        <div className="FileNavigation" style={{marginTop: 15}}>
            <BranchSelector
                current={branch}
                branches={branches}
            />
            <div className="repo">{project}</div>
        </div>
    )
};

const FilePanel = ({ title, application, children }) => {
    return (
        <div className="FilePanel">
            <div className="title">
                <FileIcon application={application} /> {title}
            </div>
            <div className="content">{children}</div>
        </div>
    );
};

export default bindComponentToApi(App, Api);