import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, far, fas);

ReactDOM.render(
    <App
        menu={globals.menu && JSON.parse(globals.menu)}
        project={globals.project}
        branch={globals.branch}
        branches={globals.branches}
        basedir={globals.basedir}
        fileName={globals.fileName}
        fileContent={globals.fileContent}
        compatibleApplication={globals.compatibleApplication}
    />,
    document.getElementById("app")
);
