import React from "react";
import MDX from "@mdx-js/runtime";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Accordion from '@material-ui/core/Accordion';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Stepper from '@material-ui/core/Stepper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import SyntaxHighlighter from 'react-syntax-highlighter';

// Componentes que podem ser usados automaticamente no .mdx, além dos padrões do markdown
const components = {
    FontAwesomeIcon,
    Accordion,
    Avatar,
    Badge,
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    Fab,
    Grid,
    Icon,
    IconButton,
    Input,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Select,
    Slider,
    Stepper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Alert,
    SyntaxHighlighter
};

/**
 *  Para transformação MDX: https://mdxjs.com/advanced/runtime
 *
 */
const MarkdownXViewer = ({ filename, content }) => {
    // Provide variables that might be referenced by JSX
    const scope = {
        filename
    };

    return (
        <MDX components={components} scope={scope}>
            {content}
        </MDX>
    );
};

export default MarkdownXViewer;
