import request from 'superagent'; 

class Api {
    constructor(component) {
        this.component = component;
    }

    /*
     Convenção:
     getXXX => setState({xxx})
     */

    getMenu = (project) => {
        if (!project) return;

        request
            .get(`/api/menu/${project}/`)
            .end((err, res) => {
                this.component.setState({menu: res.body});
            });
    };

    getFile = (project, file) => {
        if (!project) return;

        request
            .get(`/api/projects/files/${project}/${file || "README.md"}`)
            .end((err, res) => {
                if (res.text != null) {
                    history.pushState({}, '', `/home/${project}`);

                    const projectFile = res.body;
                    this.component.setState({
                        project: projectFile.project,
                        branch: projectFile.branch,
                        branches: projectFile.branches,
                        basedir: projectFile.dirName,
                        fileName: projectFile.name,
                        fileContent: projectFile.content,
                        compatibleApplication: projectFile.compatibleApplication
                    });
                }
            });
    };

    getProjects = (query) => {
        if (!query) {
            return [];
        }

        request
            .get(`/api/projects/search/${query}/`)
            .end((err, res) => {
                this.component.setState({projects: res.body});
            });
    }
}

export default Api;