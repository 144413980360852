import React, {Component, useState} from 'react';
import menuState from './MenuState';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileIcon from './FileIcon';

class NavMenu extends Component {
    render() {
        const {branch, basename, menu} = this.props;

        if (!menu) {
            return null;
        }

        return (
            <ul className="NavMenu thin-scrollbar">
                {menu.children.map((entry, i) =>
                    <MenuEntry key={i} branch={branch} basename={basename} entry={entry}/>
                )}
            </ul>
        );
    }
}

const MenuEntry = ({branch, basename, entry}) => {
    const children = entry.children || [];
    let url = ""
    if (basename) {
        url = basename + "/" + entry.url;
    }
    url = url + `?branch=${branch}&application=${entry.application}`;
    const [open, setOpen] = useState(menuState.getMenuOpen(url));
    const hasChildren = children.length > 0;

    function onClickHandler() {
        let newOpen = !open
        setOpen(newOpen)
        menuState.setMenuOpen(url, newOpen)
    }

    return (
        <li>
            {!hasChildren && (
                <a href={url}>
                    <FileIcon application={entry.application} /> {entry.title}
                </a>
            )}
            {hasChildren && (
                <div className="parentmenu" onClick={onClickHandler}>
                    {!open && <FontAwesomeIcon icon="chevron-right" aria-hidden="true" style={{ marginRight: 8, width: "1em" }} />}
                    {open && <FontAwesomeIcon icon="chevron-down" aria-hidden="true" style={{ marginRight: 8, width: "1em" }} />}
                    <a href="#">{entry.title}</a>
                </div>
            )}
            {hasChildren && open && (
                <ul className="submenu">
                    {children.map((child, i) => (
                        <MenuEntry key={i} branch={branch} basename={basename} entry={child} />
                    ))}
                </ul>
            )}
        </li>
    );
};

NavMenu.propTypes = {
    // menu
};

export default NavMenu;