class MenuState {

    getMenuOpen(menuName) {
        let menuStateJsonStr = window.sessionStorage.getItem("menuState")
        if (!menuStateJsonStr) {
            return false
        }
        let menuState = JSON.parse(menuStateJsonStr)
        return menuState[menuName]
    }

    setMenuOpen(menuName, open) {
        let menuStateJsonStr = window.sessionStorage.getItem("menuState")
        let menuState = {}
        if (menuStateJsonStr) {
            menuState = JSON.parse(menuStateJsonStr)
        }
        menuState[menuName] = open
        window.sessionStorage.setItem("menuState", JSON.stringify(menuState))
    }

    clear() {
        window.sessionStorage.removeItem("menuState")
    }
}

const menuState = new MenuState()

export default menuState