import React, { Component, PropTypes } from 'react';

import highlight from 'highlight.js';
import marked from 'marked';


marked.setOptions({
    highlight: function (code) {
        return highlight.highlightAuto(code).value;
    }
});

class MarkdownViewer extends Component {

    render() {
        const { branch, basename, content } = this.props;

        const markdownRenderer = buildMarkdownRenderer(branch, basename);
        const compiled = marked(content, {renderer: markdownRenderer});

        return (
            <div className="markdown-body" dangerouslySetInnerHTML={{__html : compiled}}></div>
        )
    }
}

// --

const buildMarkdownRenderer = (branch, basename) => {
    const renderer = new marked.Renderer();

    renderer.image = (href, title, text) => {
        const urlInfo = resolveUrl(branch, basename, href);
        urlInfo.url = urlInfo.external ? urlInfo.url : '/img/' + urlInfo.url;

        return `<img src='${urlInfo.url}' title='${title||""}'/>`;
    };

    renderer.link = (href, title, text) => {
        const urlInfo = resolveUrl(branch, basename, href);
        urlInfo.url = urlInfo.external ? urlInfo.url : '/home/' + urlInfo.url;

        return `<a href='${urlInfo.url}' ${urlInfo.external && "target='_blank'"}'>${text || href}</a>`;
    };

    return renderer;
};

const stripSlashes = (str) => {
    let stripped = str;

    if(stripped.startsWith('/')){
        stripped = stripped.substring(1);
    }

    if(stripped.endsWith('/')){
        stripped = stripped.substring(0, stripped.length - 1);
    }

    return stripped;
};

const resolveUrl = (branch, basename, url) => {
    // link externo
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return {
            url: url,
            external: true
        }
    }

    basename = stripSlashes(basename);

    // link absoluto dentro do projeto
    if (url.startsWith('/')) {
        const project = basename.split('/')[0];

        return {
            url: `${project}${url}?branch=${branch}`,
            external: false
        }
    }

    // relativo ao markdown que incluiu
    return {
        url: `${basename}/${url}?branch=${branch}`,
        external: false
    }

};

export default MarkdownViewer;