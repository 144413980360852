import React, { Component, PropTypes } from "react";
import ReactDOM from "react-dom";
import menuState from "./MenuState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BranchSelector extends Component {
    state = {
        dropdownOpen: false,
    };

    // --

    componentDidMount() {
        document.body.addEventListener("click", this.handleDocumentClick);
    }

    // --

    handleDocumentClick = (e) => {
        const clickedOutsideMe = !ReactDOM.findDOMNode(this).contains(e.target);

        if (clickedOutsideMe) {
            this.setState({ dropdownOpen: false });
        }
    };

    openDropdown = () => {
        this.setState({ dropdownOpen: true });
    };

    closeDropdown = () => {
        this.setState({ dropdownOpen: false });
    };

    select = (branch) => {
        window.location.search = `?branch=${branch}`;
        menuState.clear();
        this.closeDropdown();
    };

    // --

    render() {
        const { current, branches } = this.props;
        const { dropdownOpen } = this.state;

        return (
            <div className="BranchSelector">
                <button className="branch" onClick={this.openDropdown}>
                    Branch: <b>{current}</b>
                </button>

                {dropdownOpen && (
                    <ul className="dropdown thin-scrollbar">
                        {branches.map((branch) => (
                            <li key={branch} onClick={() => this.select(branch)}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {branch === current && <FontAwesomeIcon icon="check" />}

                                    <div className="branch-name" style={{ marginLeft: branch === current ? 0 : 15}}>
                                        {branch}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );
    }
}

BranchSelector.propTypes = {};
BranchSelector.defaultProps = {};

export default BranchSelector;
